/* eslint-disable camelcase */

import _ from 'lodash'

/**
 * convert object data to form data
 */
export const createFormData = {

  login (userId, userPassword) {
    const formData = new FormData()
    formData.append('user_id', userId)
    formData.append('user_pw', userPassword)
    return formData
  },

  ticket (ticketInfo) {
    const {
      task_cd,
      type_cd,
      title,
      request_content,
      deadline,
      status_cd,
      re_thread_no,
      re_depth_no,
      requester_company_nm,
      requester_nm,
      requester_dept,
      requester_jobtitle,
      receiver_uuid,
      receiver_company_idx,
      receiver_company_nm,
      receiver_nm,
      receiver_dept,
      receiver_jobtitle
    } = ticketInfo

    const formData = new FormData()
    formData.append('task_cd', task_cd)
    formData.append('type_cd', type_cd)
    formData.append('title', title)
    formData.append('request_content', request_content)
    formData.append('deadline', deadline)
    formData.append('status_cd', status_cd)
    formData.append('re_depth_no', re_depth_no)
    formData.append('re_thread_no,', re_thread_no)

    // TODO userInfo
    formData.append('requester_company_nm', requester_company_nm)
    formData.append('requester_nm', requester_nm)
    formData.append('requester_dept', requester_dept)
    formData.append('requester_jobtitle', requester_jobtitle)
    formData.append('receiver_uuid', receiver_uuid)
    formData.append('receiver_company_idx', receiver_company_idx)
    formData.append('receiver_company_nm', receiver_company_nm)
    formData.append('receiver_nm', receiver_nm)
    formData.append('receiver_dept', receiver_dept)
    formData.append('receiver_jobtitle', receiver_jobtitle)

    return formData
  },

  file (fileInfo) {
    const formData = new FormData()
    formData.append('file', fileInfo)
    return formData
  },

  user (accountInfo) {
    const {
      user_nm,
      email,
      phone,
      mobile
    } = accountInfo
    const formData = new FormData()
    formData.append('user_nm', user_nm)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('mobile', mobile)
    return formData
  },

  activity (activityInfo) {
    const {
      ticket_idx,
      writer_company_idx,
      writer_uuid,
      writer_nm,
      writer_jobtitle,
      title,
      content
    } = activityInfo

    const formData = new FormData()
    formData.append('ticket_idx', ticket_idx)
    formData.append('writer_company_idx', writer_company_idx)
    formData.append('writer_uuid', writer_uuid)
    formData.append('writer_nm', writer_nm)
    formData.append('writer_jobtitle', writer_jobtitle)
    formData.append('title', title)
    formData.append('content', content)

    return formData
  }
}

export const currentDate = () => {
  const today = new Date()
  document.write(today.toLocaleDateString() + '<br>')
}

export const toLocalDate = (timestamp, format) => {
  const date = new Date(timestamp * 1000)
  if (format === 'date') {
    return date.toLocaleDateString()
  } else {
    return date.toLocaleString()
  }
}

/**
 * 해당 파일 사이즈를 최대 단위로 표현, 소숫점 한자리까지 표현한다.
 * @param {Number} number - file 의 byte 사이즈
 * @returns {String} - ex) 49.1MB
 */
export function returnFileSize (number) {
  if (number < 1024) {
    return number + 'bytes'
  } else if (number > 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB'
  } else if (number > 1048576) {
    return (number / 1048576).toFixed(1) + 'MB'
  }
}

/**
 * timestamp나 datetime 문자열을 원하는 포맷으로 변경함
 * @param {String | Number} date - timestamp(INT) or datetime(STRING)
 * @param {String} f - 포맷 문자열
 * @returns {String} - 변환된 날짜시간 포맷 문자열
 */
export function dateFormat (date, f) {
  if (!f || f === '') f = 'yyyy-MM-dd HH:mm:ss'
  var dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  var dayOfWeekKo = ['일', '월', '화', '수', '목', '금', '토']
  var d = timestampToDateObject(date)

  return f.replace(/(yyyy|yy|MM|dd|E|K|hh|mm|ss|a\/p)/gi, function ($1) {
    switch ($1) {
      case 'yyyy': return d.getFullYear()
      case 'yy': return zf(d.getFullYear() % 100, 2)
      case 'MM': return zf(d.getMonth() + 1, 2)
      case 'dd': return zf(d.getDate(), 2)
      case 'E': return dayOfWeek[d.getDay()]
      case 'K': return dayOfWeekKo[d.getDay()]
      case 'HH': return zf(d.getHours(), 2)
      case 'hh': return zf((d.getHours() % 12 ? d.getHours() % 12 : 12), 2)
      case 'mm': return zf(d.getMinutes(), 2)
      case 'ss': return zf(d.getSeconds(), 2)
      case 'a/p': return d.getHours() < 12 ? 'AM' : 'PM'
      default: return $1
    }
  })
}

export function timestampToDateObject (ts) {
  if (!Number.isInteger(ts)) {
    return new Date(ts)
  }

  const len = parseInt(ts).toString().length
  if (len === 13) {
    return new Date(ts)
  } else if (len === 10) {
    return new Date(ts * 1000)
  } else {
    return null
  }
}

/**
 * 문자열 앞에 0을 채워넣어 원하는 길이로 만들어 반환
 * @param {String | Number} str - 변환하고자 하는 문자열(숫자도 가능)
 * @param {Number} len - 원하는 길이
 * @returns {String} 변환된 문자열
 */
function zf (str, len) {
  str = String(str)
  if (str.length >= len) {
    return str.substring(0, len)
  } else {
    let newStr = ''
    for (let i = 0; i < len - str.length; i++) {
      newStr += '0'
    }
    newStr += str
    return newStr
  }
}

export function getExistInArray (array, value) {
  const index = _.findIndex(array, o => { return o === value })
  if (index >= 0) {
    return true
  } else {
    return false
  }
}

/**
  숫자를 3자리마다 , 추가하여 반환
  pre: 접두사, suf: 접미사
**/
export function numberformat (value, pre, suf) {
  if (!value) return '0'
  value = value * 1
  return pre + value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') + suf
}
