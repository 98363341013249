import axios from 'axios'
import { handleAxiosError } from './error'
import { O2O_SERVICE_URL } from '@/assets/js/url'

const o2oservice = axios.create({
  withCredentials: true,
  baseURL: O2O_SERVICE_URL
})

// o2oservice.headers = { 'Content-Type': 'application/json' }
// o2oservice.interceptors.request.use((config) => config, (err) => { throw new Error(err) })
// o2oservice.interceptors.response.use((response) => response, handleAxiosError)

o2oservice.interceptors.request.use((config) => {
  config.headers.tk = window.localStorage.getItem('tk') || ''
  config.headers.rftk = window.localStorage.getItem('rftk') || ''
  return config
})
o2oservice.interceptors.response.use((response) => response, async (error) => {
  if (error.response && error.response.status === 401) {
    const token = await handleAxiosError(error.response.data.code)
    if (token !== '') {
      o2oservice.defaults.headers.tk = token
      error.config.headers.tk = token
      return o2oservice.request(error.config)
    }
  }
  return Promise.reject(error)
})
/**
 * server의 API endpoints를 나열하고
 * 해당 API의 커스텀 error case에 대해서만 작성.
 */
export default {
  // Product API  ************************************************************************************************************
  // 자회사 상품 목록 조회
  async getCompanyProductList (params) {
    return o2oservice.get('/provider/me/product', { params })
  },

  // 오픈상품 목록 조회
  async getOpenProductList (params) {
    return o2oservice.get('/market/product', { params })
  },

  // 상품 idx 배열로 목록 조회
  async getProductList (languageCd, proudctIdxArray) {
    const params = { language_cd: languageCd }
    let api = '/public/template/product'
    for (let i = 0; i < proudctIdxArray.length; i++) {
      if (i === 0) {
        api += '?product_idx_list=' + proudctIdxArray[i]
      } else {
        api += '&product_idx_list=' + proudctIdxArray[i]
      }
    }
    return o2oservice.get(api, { params })
  },

  // 상품 하나의 상세정보 조회
  async getProduct (proudctIdx) {
    return o2oservice.get(`/product/${proudctIdx}`)
  },

  // Order API  **************************************************************************************************************
  // 주문 목록
  async getOrderList (params) {
    return o2oservice.get('/order', { params })
  },

  async getProviderOrderList (params) {
    return o2oservice.get('/provider/me/order', { params })
  },

  // 주문 상세
  async getOrder (orderIdx, params) {
    return o2oservice.get(`/order/${orderIdx}`, { params })
  }
}
