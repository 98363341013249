/* eslint-disable no-useless-constructor */
import store from '@/store'

import account from '@/apis/account'

const checkTokenError = (code) => {
  switch (code) {
    case '4010' : throw new InvalidTokenError()
    case '4011' : throw new ExpiredTokenError()
    case '4012' : throw new RefreshTokenError()
  }
}

export const handleAxiosError = async (errCode) => {
  try {
    checkTokenError(errCode)
  } catch (tokenError) {
    let token = ''
    if (tokenError instanceof InvalidTokenError) {
      store.dispatch('Auth/logout')
      store.dispatch('error', { message: '유효하지 않은 로그인입니다.', routerPath: '/login' })
    } else if (tokenError instanceof ExpiredTokenError) {
      token = await account.refreshToken()
    } else if (tokenError instanceof RefreshTokenError) {
      store.dispatch('Auth/logout')
      store.dispatch('error', { message: '로그인이 만료되었습니다.', routerPath: '/login' })
    }
    return token
  }
}

class ExpiredTokenError extends Error {
  constructor (...params) {
    super(...params)
  }
}

class InvalidTokenError extends Error {
  constructor (...params) {
    super(...params)
  }
}

class RefreshTokenError extends Error {
  constructor (...params) {
    super(...params)
  }
}
