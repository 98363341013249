/**
 * Auth VUEX sotre 모듈
 * @author shembaby
 */

// import _ from 'lodash'
import accountAPI from '@/apis/account'
import { createFormData } from '@/assets/js/common'

export default {
  namespaced: true,
  state: {
    isLogin: false,
    userInfo: {}
  },
  getters: {
    getIsLogin: state => state.isLogin,
    getUserInfo: state => state.userInfo
  },
  mutations: {
    _setIsLogin (state, loginYn) {
      state.isLogin = loginYn
    },
    _setUserInfo (state, info) {
      state.userInfo = info
    },
    _setUpdatedUserInfo (state, info) {
      state.userInfo.name = info.user_nm
      state.userInfo.email = info.email
      state.userInfo.phone = info.phone
      state.userInfo.mobile = info.mobile
    }
  },
  actions: {
    async login ({ dispatch, commit }, payload) {
      const req = createFormData.login(payload.loginId, payload.loginPassword)
      const res = await accountAPI.getUser(req)
      const userInfo = res.data.items.user
      commit('_setIsLogin', true)
      dispatch('setUserInfo', userInfo)
    },
    setUserInfo ({ commit }, info) {
      const userInfo = {
        company: info.company ? info.company : '',
        dept: info.dept ? info.dept : '',
        email: info.email ? info.email : '',
        loginId: info.login_id ? info.login_id : '',
        mobile: info.mobile ? info.mobile : '',
        name: info.name ? info.name : '',
        nick: info.nick ? info.nick : '',
        phone: info.phone ? info.phone : '',
        position: info.position ? info.position : '',
        profile: info.profile ? info.profile : '',
        seller: info.seller != null ? info.seller : false,
        partner: info.partner != null ? info.partner : false
      }
      commit('_setUserInfo', userInfo)
      return Promise.resolve()
    },
    logout ({ commit }) {
      commit('_setIsLogin', false)
      commit('_setUserInfo', null)
      return Promise.resolve()
    },
    updateUserInfo ({ commit }, info) {
      commit('_setUpdatedUserInfo', info)
      return Promise.resolve()
    }
  }
}
