import axios from 'axios'
import { handleAxiosError } from './error'
import { SMART_MEDIA_URL } from '@/assets/js/url'

const smartmedia = axios.create({
  withCredentials: true,
  baseURL: SMART_MEDIA_URL
})

smartmedia.interceptors.request.use((config) => {
  config.headers.tk = window.localStorage.getItem('tk') || ''
  config.headers.rftk = window.localStorage.getItem('rftk') || ''
  return config
})
smartmedia.interceptors.response.use((response) => response, async (error) => {
  if (error.response && error.response.status === 401) {
    const token = await handleAxiosError(error.response.data.code)
    if (token !== '') {
      smartmedia.defaults.headers.tk = token
      error.config.headers.tk = token
      return smartmedia.request(error.config)
    }
  }
  return Promise.reject(error)
})

/**
 * server의 smartmedia API endpoints를 나열하고
 * 해당 API의 커스텀 error case에 대해서만 작성.
 */
export default {
  // Marker API  *************************************************************************************************************
  async getMarkerList (params) {
    return await smartmedia.get('/marker', { params })
  },

  async getMarker (markerUUID) {
    return smartmedia.get(`/marker/${markerUUID}`)
  },

  async createMarker (req) {
    return smartmedia.post('/marker', req)
  },

  async updateMarker (markerUUID, req) {
    return smartmedia.put(`/marker/${markerUUID}`, req)
  },

  async deleteMarker (markerUUID) {
    return smartmedia.delete(`/marker/${markerUUID}`)
  },

  async uploadMarkerFile (markerUUID, req, uploadFunc) {
    return smartmedia.post(`/marker/${markerUUID}/file`, req)
  },

  async downloadMarkerFile (markerUUID, downloadType) {
    return smartmedia.get(`/marker/${markerUUID}/file`, { params: { download_type: downloadType } })
  },

  // File API ****************************************************************************************************************
  async uploadFile (req, uploadFunc) {
    return smartmedia.post('/file', req)
  },

  async downloadFile (fileIdx, downloadType) {
    return smartmedia.get(`/file/${fileIdx}`, { params: { download_type: downloadType } })
  },

  async deleteFile (fileIdx) {
    return smartmedia.delete(`/file/${fileIdx}`)
  },

  // Interface API ***********************************************************************************************************
  async getInterfaceList (params) {
    return smartmedia.get('/interface', { params })
  },

  async createInterface (req) {
    return smartmedia.post('/interface', req)
  },

  async getInterface (interfaceUUID) {
    return smartmedia.get(`/interface/${interfaceUUID}`)
  },

  async updateInterface (interfaceUUID, req) {
    return smartmedia.put(`/interface/${interfaceUUID}`, req)
  },

  async deleteInterface (interfaceUUID) {
    return smartmedia.delete(`/interface/${interfaceUUID}`)
  },

  async connectInterface (markerUUID, interfaceUUID) {
    return smartmedia.post(`/interface/${interfaceUUID}/marker/${markerUUID}`)
  },

  async disconnectInterface (markerUUID, interfaceUUID) {
    return smartmedia.delete(`/interface/${interfaceUUID}/marker/${markerUUID}`)
  },

  // Interface > Layer API ***************************************************************************************************
  async createLayer (interfaceUUID, req) {
    return smartmedia.post(`interface/${interfaceUUID}/layer`, req)
  },

  async updateLayer (layerUUID, req) {
    return smartmedia.put(`/layer/${layerUUID}`, req)
  },

  async deleteLayer (layerUUID) {
    return smartmedia.delete(`/layer/${layerUUID}`)
  },

  async createCustomUnit (layerUUID, req) {
    return smartmedia.post(`/layer/${layerUUID}/custom_unit`, req)
  },

  async createTemplateUnit (layerUUID, req) {
    return smartmedia.post(`/layer/${layerUUID}/template_unit`, req)
  },

  // Template **************************************************************************************************************
  async getTemplateList (params) {
    return smartmedia.get('/template', { params })
  },

  async createTemplate (req) {
    return smartmedia.post('/template', req)
  },

  async getTemplate (templateIDX) {
    return smartmedia.get(`/template/${templateIDX}`)
  },

  async updateTemplate (templateIDX, req) {
    return smartmedia.put(`/template/${templateIDX}`, req)
  },

  async deleteTemplate (templateIDX) {
    return smartmedia.delete(`/template/${templateIDX}`)
  },

  async uploadTemplateFile (templateIDX, req) {
    return smartmedia.post(`/template/${templateIDX}/file`, req)
  },

  async downloadTemplateFile (templateIDX, downloadType) {
    return smartmedia.get(`/template/${templateIDX}/file`, { params: { download_type: downloadType } })
  },

  // Object **************************************************************************************************************
  async getObjectList (params) {
    return smartmedia.get('/object', { params })
  },

  async createObject (req) {
    return smartmedia.post('/object', req)
  },

  async getObject (objectIDX) {
    return smartmedia.get(`/object/${objectIDX}`)
  },

  async updateObject (objectIDX, req) {
    return smartmedia.put(`/object/${objectIDX}`, req)
  },

  async deleteObject (objectIDX) {
    return smartmedia.delete(`/object/${objectIDX}`)
  },

  async uploadObjectFile (objectIDX, req, uploadFunc) {
    return smartmedia.post(`/object/${objectIDX}/file`, req)
  },

  async downloadObjectFile (objectIDX, downloadType) {
    return smartmedia.get(`/object/${objectIDX}/file`, { params: { download_type: downloadType } })
  }

}
