import { createPopper } from '@popperjs/core'
import _ from 'lodash'

export const commonMixin = {
  data: function () {
    return {
    }
  },
  computed: {
    perPageList () {
      const list = []
      _.forEach([10, 20, 50], item => {
        const text = this.$t('basic.sort-option.per-page', { n: item })
        list.push({ label: text || `${item}개씩 보기`, value: item })
      })
      return list
    },
    orderByList () {
      const list = []
      // list.push({ label: this.t('basic.sort-option.order-by01', '최신 등록순'), value: 'RECENT' })
      // list.push({ label: this.t('basic.sort-option.order-by02', '가나다순'), value: 'ALPHABET' })
      list.push({ label: this.t('basic.sort-option.order-by01', '최신 등록순'), value: 'recent' })
      list.push({ label: this.t('basic.sort-option.order-by02', '가나다순'), value: 'alphabet' })
      return list
    },
    permissionList () {
      const list = []
      list.push({ label: this.t('basic.permission-option.all', '전체'), value: null })
      list.push({ label: this.t('basic.permission-option.permission-no', '모두 사용'), value: 'N' })
      list.push({ label: this.t('basic.permission-option.permission-user', '추가된계정만 사용'), value: 'Y' })
      return list
    },
    templateCodeList () {
      const list = []
      list.push({ label: this.t('basic.template-option.cafe', '카페'), value: 'cafe' })
      list.push({ label: this.t('basic.template-option.kiosk', '키오스크'), value: 'kiosk' })
      list.push({ label: this.t('basic.template-option.clothes', '의류'), value: 'clothes' })
      list.push({ label: this.t('basic.template-option.iot', 'IOT'), value: 'iot' })
      list.push({ label: this.t('basic.template-option.nft', 'NFT'), value: 'nft' })
      return list
    }
  },
  methods: {
    alertSetting (message, routerPath, functionName) {
      const payload = { message: message, routerPath: routerPath, functionName: functionName }
      this.$store.dispatch('alert', payload)
      this.$bvModal.show('alert-modal')
    },
    loadingSetting (loading) {
      const payload = { loading }
      this.$store.dispatch('loading', payload)
    },
    headerMenuSetting (menuCd) {
      const payload = { menuCd: menuCd }
      this.$store.dispatch('headerMenu', payload)
    },
    getCodeValue (cdType, code) {
      let position = ''
      if (cdType === 'productType') {
        position = 'product.product-type.' + code
      } else if (cdType === 'currency') {
        position = 'product.currency.' + code
      } else if (cdType === 'language') {
        position = 'product.language.' + code
      } else if (cdType === 'orderStatus') {
        position = 'order.order-status.' + code
      }
      return this.t(position, '')
    },
    t (str, fallbackStr) {
      // use `$te` and `$t
      // i18n 초기값을 입력하여 현재 입력값을 명확히 보여주기 위해 만든 함수
      return this.$t && this.$te
        ? (this.$te(str) ? this.$t(str) : fallbackStr)
        : (fallbackStr || str)
    },
    dropdownPositionTop (dropdownList, component, { width }) {
      // v-select 위치 조절을 위한 함수
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          }]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }
  }
}
