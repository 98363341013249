import axios from 'axios'
import { handleAxiosError } from './error'
import { ACCOUNT_URL } from '@/assets/js/url'

export const account = axios.create({
  withCredentials: true,
  baseURL: ACCOUNT_URL
})

account.interceptors.request.use((config) => {
  config.headers.tk = window.localStorage.getItem('tk') || ''
  config.headers.rftk = window.localStorage.getItem('rftk') || ''
  return config
})
account.interceptors.response.use((response) => response, async (error) => {
  if (error.response && error.response.status === 401) {
    const token = await handleAxiosError(error.response.data.code)
    if (token !== '') {
      account.defaults.headers.tk = token
      error.config.headers.tk = token
      return account.request(error.config)
    }
  }
  return Promise.reject(error)
})

/**
 * server의 account API endpoints를 나열하고
 * 해당 API의 커스텀 error case에 대해서만 작성.
 */
export default {
  async getUser (req) {
    const res = await account.post('/user/login', req)

    // 사용자 계정이 존재하지 않는 경우 (401)
    // 비밀번호가 불일치 할 경우 (401)
    // 이메일이나 비밀번호 형식이 맞지 않는 경우 (400)
    // 데이터(네트워크에러)가 전달되지 않았을 경우 (400)

    // 현재 노크의 어카운트 모듈은 모든 실패에 '200' 성공했다는 응답을 줍니다.
    // 결과값에 뭔지 일일히 살펴보고 오류 처리를 해야함-_-

    if (res.data.result === 'N') {
      throw new Error('잘못된 아이디 혹은 비밀번호 입니다.')
    }
    window.localStorage.setItem('tk', res.data.tk)
    window.localStorage.setItem('rftk', res.data.rftk)
    return res
  },

  async getKnockStaffList () {
    return account.get('/company/1/user')
  },

  async refreshToken () {
    const res = await account.get('/user/token')
    // console.log('refreshToken 받을 때 결과물 확인', res)
    const token = res.data.data
    window.localStorage.setItem('tk', token)
    return token
  },

  async updateAccount (req) {
    return account.put('/user/me', req)
  },

  async updatePassword (req) {
    return account.put('/user/me', req)
  },

  async findIDList (req) {
    return account.get('/user/find/login-id', { params: req })
  },

  async findID (req) {
    return account.get('/user/find/password', { params: req })
  },

  async resetPW (userUUID, req) {
    return account.put(`/user/${userUUID}/password`, req)
  },

  async checkDuplicateID (req) {
    return account.get('/user/duplicateCheck', { params: req })
  },

  async getCompanyList () {
    return account.get('/company')
  },

  async join (req) {
    return account.post('/user', req)
  },

  async getUserList () {
    return await account.get('/user', { params: { confirm: 'N' } })
  }

}
