import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import i18n from '../i18n'
import { localeChanged } from 'vee-validate'

// 하위 모듈 import
import * as module from './module'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['Auth']
  })],
  state: {
    alertMessage: '',
    alertRouterPath: '',
    alertFunctionName: '',
    errorMessage: '',
    errorRouterPath: '',
    headerMenuCd: '',
    headerPersonLayerVisible: false,
    viewTypeList: [
      { menuCd: 'marker', viewType: 'tile' },
      { menuCd: 'interface', viewType: 'tile' },
      { menuCd: 'template', viewType: 'list' }
    ],
    languageList: [
      { code: 'ko', label: '한국어' },
      { code: 'en', label: 'English' }
    ],
    languageCd: i18n.locale,
    isLoading: false
  },
  getters: {
    getViewType: (state) => (menuCd) => {
      const data = state.viewTypeList.find(item => item.menuCd === menuCd)
      if (data) {
        return data.viewType
      } else {
        return 'tile'
      }
    }
  },
  mutations: {
    _setAlertMessage (state, message) {
      state.alertMessage = message
    },
    _setAlertRouterPath (state, routerPath) {
      state.alertRouterPath = routerPath
    },
    _setAlertfunctionName (state, functionName) {
      state.alertFunctionName = functionName
    },
    _setErrorMessage (state, message) {
      state.errorMessage = message
    },
    _setErrorRouterPath (state, routerPath) {
      state.errorRouterPath = routerPath
    },
    _setHeaderMenuCd (state, menuCd) {
      state.headerMenuCd = menuCd
    },
    _setHeaderPersonLayerVisible (state, visible) {
      state.headerPersonLayerVisible = visible
    },
    _setViewType (state, payload) {
      const data = state.viewTypeList.find(item => item.menuCd === payload.menuCd)
      data.viewType = payload.viewType
    },
    _setIsLoading (state, loading) {
      state.isLoading = loading
    }
  },
  actions: {
    alert ({ commit }, payload) {
      if (!payload.functionName) {
        payload.functionName = ''
      }
      commit('_setAlertMessage', payload.message)
      commit('_setAlertRouterPath', payload.routerPath)
      commit('_setAlertfunctionName', payload.functionName)
    },
    error ({ commit }, payload) {
      commit('_setErrorMessage', payload.message)
      commit('_setErrorRouterPath', payload.routerPath)
    },
    headerMenu ({ commit }, payload) {
      commit('_setHeaderMenuCd', payload.menuCd)
    },
    setViewType ({ commit }, payload) {
      commit('_setViewType', payload)
    },
    changeLanguage () {
      localeChanged()
    },
    loading ({ commit }, payload) {
      commit('_setIsLoading', payload.loading)
    }
  },
  modules: module.default
})
