import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './filter/filters'
import i18n from './i18n'
import { commonMixin } from '@/mixin/common.js'

import BootstrapVue from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-select/dist/vue-select.css'

import './assets/css/common.css'
import './assets/css/common.scss'
import './assets/font/fonts.css'

import smartmediaAPI from '@/apis/smartmedia'
import o2oserviceAPI from '@/apis/o2oservice'

import './vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.mixin(commonMixin)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('v-select', vSelect)

Vue.prototype.$http = {}
Vue.prototype.$http.media = smartmediaAPI
Vue.prototype.$http.o2o = o2oserviceAPI

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
