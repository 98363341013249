let ACCOUNT_URL = 'https://api.knowck.com/account'
let SMART_MEDIA_URL = 'https://api.knowck.com/smartmedia/api'
let O2O_SERVICE_URL = 'https://api.knowck.com/o2o'
let FILE_URL = 'https://api.knowck.com/smartmedia/api'

export const lastElementOfCurrentUrl = () => {
  return window.location.href.split('/').splice(-1)[0]
}

if (location.hostname.startsWith('dev-test.knowck.com')) {
  ACCOUNT_URL = 'https://dev-test.knowck.com/account'
  SMART_MEDIA_URL = 'https://dev-test.knowck.com/smartmedia/api'
  O2O_SERVICE_URL = 'https://dev-test.knowck.com/o2o'
  FILE_URL = 'https://dev-test.knowck.com/smartmedia/api'
} else if (location.hostname.startsWith('192.168.0.152')) {
  ACCOUNT_URL = 'http://192.168.0.152:7100/account'
  SMART_MEDIA_URL = 'http://192.168.0.152:5500/smartmedia/api'
  O2O_SERVICE_URL = 'http://192.168.0.152:6500/o2o'
  FILE_URL = 'https://api.knowck.com/smartmedia/api'
} else if (location.hostname.startsWith('192.168.0.99')) {
  ACCOUNT_URL = 'http://192.168.0.99/account'
  SMART_MEDIA_URL = 'http://192.168.0.99/smartmedia/api'
  O2O_SERVICE_URL = 'http://192.168.0.57:6500/o2o'
  FILE_URL = 'http://192.168.0.99/smartmedia/api'
} else if (location.hostname.startsWith('www.cloudvision.biz')) {
  FILE_URL = 'https://www.cloudvision.biz/smartmedia/api'
} else if (location.hostname.startsWith('192.168')) {
  ACCOUNT_URL = 'http://192.168.0.201:5500/account'
  SMART_MEDIA_URL = 'http://192.168.0.201:5500/smartmedia/api'
  O2O_SERVICE_URL = 'http://192.168.0.57:6500/o2o'
  FILE_URL = 'http://192.168.0.201:5500/smartmedia/api'
}

export { ACCOUNT_URL, SMART_MEDIA_URL, O2O_SERVICE_URL, FILE_URL }
