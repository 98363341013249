<template>
  <div id="app">
    <Spinner v-if="isLoading" />
    <LayoutSetting>
      <router-view />
    </LayoutSetting>
    <Alert></Alert>
    <Error></Error>
  </div>
</template>

<script>
import { mapActions, createNamespacedHelpers, mapState } from 'vuex'
const { mapState: authMapState } = createNamespacedHelpers('Auth')

const LayoutSetting = () => import('@/layout/LayoutSetting.vue')
const Alert = () => import('@/components/Alert.vue')
const Error = () => import('@/components/Error.vue')
const Spinner = () => import('@/components/Spinner.vue')

export default {
  name: 'App',
  computed: {
    ...authMapState([
      'isLogin',
      'userInfo'
    ]),
    ...mapState([
      'isLoading'
    ])
  },
  components: {
    Alert,
    Error,
    LayoutSetting,
    Spinner
  },
  created () {
    let locale = navigator.language || navigator.userLanguage
    locale = locale.substring(0, 2)
    if (locale !== 'ko') locale = 'en'
    this.$i18n.locale = locale
  },
  methods: {
    ...mapActions([
      'alert',
      'headerMenuInfo'
    ])
  }
}
</script>

<style>
</style>
