import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

const Login = () => import('../views/join/Login.vue')
const Join = () => import('../views/join/Join.vue')
const FindID = () => import('../views/join/FindID.vue')
const FindPW = () => import('../views/join/FindPW.vue')
const MyPage = () => import('../views/mypage/View.vue')

const MarkerList = () => import('../views/marker/List.vue')
const MarkerDetail = () => import('../views/marker/Detail.vue')

const InterfaceList = () => import('../views/interface/List.vue')
const InterfaceDetail = () => import('../views/interface/Detail.vue')

const ProductList = () => import('../views/product/List.vue')
const ProductDetail = () => import('../views/product/Detail.vue')

const OrderList = () => import('../views/order/List.vue')
const OrderDetail = () => import('../views/order/Detail.vue')

const TemplateList = () => import('../views/template/List.vue')
const TemplateDetail = () => import('../views/template/Detail.vue')

const ObjectList = () => import('../views/object/List.vue')
const ObjectDetail = () => import('../views/object/Detail.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/marker' },
  { path: '/join', name: 'Join', component: Join, meta: { layout: 'LayoutJoin', authRequired: false } },
  { path: '/FindID', name: 'FindID', component: FindID, meta: { layout: 'LayoutJoin', authRequired: false } },
  { path: '/FindPW', name: 'FindPW', component: FindPW, meta: { layout: 'LayoutJoin', authRequired: false } },
  { path: '/login', name: 'Login', component: Login, meta: { layout: 'LayoutJoin', authRequired: false } },

  { path: '/mypage', name: 'MyPage', component: MyPage, meta: { authRequired: true } },

  { path: '/marker', name: 'MarkerList', component: MarkerList, meta: { authRequired: true } },
  { path: '/marker/:marker_uuid', name: 'MarkerDetail', component: MarkerDetail, props: true, meta: { authRequired: true } },

  { path: '/interface', name: 'InterfaceList', component: InterfaceList, meta: { authRequired: true } },
  { path: '/interface/:interface_uuid', name: 'InterfaceDetail', component: InterfaceDetail, props: true, meta: { authRequired: true } },
  { path: '/interface/:interface_uuid/:mode', name: 'InterfaceDetailSub', component: InterfaceDetail, props: true, meta: { authRequired: true } },

  { path: '/product', name: 'ProductList', component: ProductList, meta: { authRequired: false } },
  { path: '/product/:product_idx', name: 'ProductDetail', component: ProductDetail, props: true, meta: { authRequired: false } },

  { path: '/template', name: 'TemplateList', component: TemplateList, meta: { authRequired: true } },
  { path: '/template/:template_idx', name: 'TemplateDetail', component: TemplateDetail, props: true, meta: { authRequired: true } },

  { path: '/order', name: 'OrderList', component: OrderList, meta: { authRequired: false } },
  { path: '/order/:merchant_uid', name: 'OrderDetail', component: OrderDetail, props: true, meta: { authRequired: false } },

  { path: '/object', name: 'ObjectList', component: ObjectList, meta: { authRequired: true } },
  { path: '/object/:object_idx', name: 'ObjectDetail', component: ObjectDetail, props: true, meta: { authRequired: true } }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
})

router.beforeEach(function (to, from, next) {
  const isLogin = store.getters['Auth/getIsLogin']
  if (to.matched.some(record => {
    return record.meta.authRequired
  })) {
    // 이 라우트는 인증이 필요하며 로그인 한 경우 확인하십시오.
    // 그렇지 않은 경우 로그인 페이지로 리디렉션하십시오.
    if (!isLogin) {
      next('/login')
    } else {
      next()
    }
  } else {
    next() // 반드시 next()를 호출하십시오!
  }
})

export default router
