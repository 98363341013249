const file = require.context('.', false, /\.js$/)
const storeModule = {}

// index.js를 제외한 모든 js를 file명을 키로 modules에 담아 리턴
file.keys().forEach((key) => {
  if (key === './index.js') return
  storeModule[key.replace(/(\.\/|\.js)/g, '')] = file(key).default
})

export default storeModule
